.support-menu {
    padding-top: 5px;
    font-family: "Rota";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    text-decoration: none;
}

.phone-number-suport {
    margin-left: 10px;
}

.container:has(.main-screen-page-is-landing) {
    max-width: 100% !important;
    padding: 0px;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item .specializations-carousel-item-content {
    width: 100%;
    max-width: 115px;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item .specializations-carousel-item-content > div[style^="position: relative"] {
    width: 100%;
}

.homepage-benefits .benefit-item .benefit-img-wrapper > div {
    width: 62px;
}

.patient-main-screen-row1-image {
    display: flex;
    align-items: center;
}

.patient-main-screen-row1, .how-it-works-img {
    width: 100%;
    display: block;
}

.how-it-works-wrapper .container {
    background-color: #ffffff;
    border-radius: 12px;
    max-width: 1340px !important;
}

.main-screen-gradient2 {
    background-image: url("../../shared/assets/images/main-screen-gradient2.jpg");
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
}

.main-screen-gradient2 .container,
.main-screen-gradient3 .container {
    max-width: 1340px !important;
}

.main-screen-gradient2 .accordion-item {
    /*min-width: 400px;*/
}

.main-screen-gradient2 h1 {
    margin: 0 auto;
    padding: 50px 0;
    /*width: 70%;*/
    text-align: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 110%;
    color: #000000;
    max-width: 720px;
}

.main-screen-gradient2 .main-screen-gradient2-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.main-screen-gradient2 .demo-img {
    display: block;
    width: 100%;
    max-width: 460px;
}

.main-screen-gradient2 .box-white .circle {
    border-radius: 100%;
    width: 62px;
    min-width: 62px;
    height: 62px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
    margin-right: 20px;
}

.main-screen-gradient2 .box-white button[aria-expanded="false"] .circle {
    background: #F2F3F3;
}

.main-screen-gradient2 .box-white button[aria-expanded="true"] .circle {
    background: #00CFB4;
}

.main-screen-gradient2 .box-white button[aria-expanded="false"] .circle span {
    color: #001315;
    height: 28px;
}

.main-screen-gradient2 .box-white button[aria-expanded="true"] .circle span {
    color: #ffffff;
    height: 28px;
}

.main-screen-gradient2 .box-white .description {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 110%;
    color: #001315;
}

.main-screen-gradient2 .box-white .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
}

.main-screen-gradient2 .homepage-specializations {
    background-color: #fff;
    border-radius: 12px;
    margin-top: 100px;
    padding-bottom: 50px;
}

.main-screen-gradient2 .homepage-specializations h1 {
    margin: 0 auto;
    padding: 50px 0;
    /*width: 70%;*/
    text-align: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 110%;
    color: #000000;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item {
    background: #F2F2F3;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    cursor: pointer;
    height: 250px;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item .specializations-carousel-item-content {
    padding-left: 20px;
    padding-right: 20px;
}

.specializations-carousel-item-hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item-hover img {
    padding-bottom: 0;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item-hover .name {
    margin: 14px 0;
}

.hoverable-show {
    display: none;
}

.hoverable-hide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hoverable-item:hover .hoverable-show {
    display: block;
}

.hoverable-item:hover .hoverable-hide {
    display: none;
}


.main-screen-gradient2 .specializations-carousel .specializations-carousel-item .name {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 110%;
    text-align: center;
    color: #001315;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item img {
    height: 80px;
    padding-bottom: 15px;
}

.main-screen-gradient2 .specializations-carousel .specializations-carousel-item .specializations-carousel-item-hover img {
    object-fit: contain;
}

.how-it-works-wrapper .specializations-carousel .next-slider,
.main-screen-gradient2 .specializations-carousel .next-slider,
.main-screen-gradient3 .feedback-carousel .next-slider,
.main-screen-gradient3 .defiro-carousel .next-slider {
    position: absolute;
    top: calc(50% - 18px);
    height: 35px;
    width: 35px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    left: initial;
    right: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.main-screen-gradient3 .next-slider .custom-icon,
.main-screen-gradient3 .prev-slider .custom-icon {
    margin-right: 0;
}

.how-it-works-wrapper .specializations-carousel .prev-slider,
.main-screen-gradient2 .specializations-carousel .prev-slider,
.main-screen-gradient3 .defiro-carousel .prev-slider,
.main-screen-gradient3 .feedback-carousel .prev-slider {
    position: absolute;
    top: calc(50% - 18px);
    height: 35px;
    width: 35px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    transition: opacity 0.25s;
    left: 5px;
    right: initial;
    align-items: center;
    justify-content: center;
    display: flex;
}

.homepage-benefits .benefit-collection {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    justify-content: space-between;
    margin-right: 0px;
    margin-left: 0px;
}

.homepage-benefits .benefit-item {
    align-items: center;
    background: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    padding: 56px 66px;
    text-align: center;
    max-width: 413px;
    height: 100%
}

.benefit-item-container {
    padding-bottom: 20px;
}

@media (min-width: 1441px) {
    section.main-hero-1 .main-hero-1-container {
        width: 1600px
    }

    section.main-hero-1 .main-hero-1-container-menu {
        width: 1600px
    }

}

@media (max-width: 1024px) {
    .homepage-benefits .benefit-item {
        padding: 40px 20px;
    }
}

.homepage-benefits .benefit-item .benefit-img-wrapper {
    margin-bottom: 15px;
    border-radius: 100%;
    width: 132px;
    height: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homepage-benefits .benefit-item .title {
    display: block;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    text-align: center;
    color: #001315;
}

.main-screen-gradient2 .homepage-benefits h1 {
    margin: 0 auto;
    padding: 90px 0 50px;
    text-align: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 110%;
    color: #000000;
}

.main-screen-gradient3 {
    background-image: url("../../shared/assets/images/main-screen-gradient3.jpg");
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.homepage-permanent-access {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 40px;
}

.homepage-permanent-access p {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    width: 100%;
}

.homepage-permanent-access .orange-button {
    width: 30%;
    margin: 0 auto;
}

.main-screen-page .custom-icon {
    margin-right: 0;
}

.homepage-feedback h1 {
    margin: 0 auto;
    padding: 90px 0 50px;
    text-align: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 110%;
    color: #000000;
}

.homepage-newsletter {
    margin-top: 80px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 40px;
}

.homepage-newsletter img {
    height: 80px;
    display: block;
    margin-bottom: 20px;
}

.homepage-newsletter h1 {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-bottom: 40px;
}

.homepage-newsletter h4 {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 30px auto;
    max-width: 814px;
}

.homepage-newsletter p {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    text-align: center;
    color: #BFC4C5;
    margin-top: 20px;
}

.homepage-newsletter .orange-button {
    position: absolute;
    top: 4%;
    width: auto;
    right: 20.5%;
    padding: 10px;
    min-height: auto;
}

.homepage-doctor-section {
    margin-top: 80px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 40px;
    display: flex;
    flex-direction: column;
}

.homepage-doctor-section .homepage-doctor-section-logo {
    display: block;
    max-width: 300px;
    margin: 0 auto;
    padding-bottom: 30px;
}

.homepage-doctor-section h1 {
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin: 40px auto;
    max-width: 712px;
}

.homepage-doctor-section .green-button {
    width: auto;
    margin: 0 auto 40px;
}

.demo-image-wrapper {
    text-align: center;
}

@supports (not (-ms-ime-align:auto)) {
    .homepage-newsletter input.form-control {
        font-family: Rota, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #001315;
        padding: 19px 20px;
        height: 56px !important;
        transition: all 0.2s ease-out;
        background: #FFFFFF;
        border: 1px solid #E5E7E8;
        border-radius: 15px;
        width: 60%;
        margin: 0 auto;
    }

    .homepage-newsletter input.form-control + label {
        font-family: Rota, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #80898A;
        padding-left: 20px;
        position: absolute;
        top: 18px;
        width: auto;
        left: 21%;
        transition: all 0.2s ease-out;
    }
}

.accordion-wrapper-div {
    width: 100%;
    max-width: 606px;
    border-radius: 12px;
    background-color: #FFFFFF;
    padding: 10px;
}

.main-screen-gradient2 .box-white .accordion-button {
    padding: 16px 30px;
}

.accordion-wrapper-div .accordion-item {
    border-color: #FFFFFF !important;
}

.accordion-wrapper-div .accordion-item .accordion-button.collapsed {
    border-bottom: 1px solid #E5E7E8;
}

.accordion-wrapper-div .accordion-item:last-child .accordion-button.collapsed {
    border-bottom: none;
}

.main-screen-gradient2 .accordion-body {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 110%;
    padding: 0px 30px 30px;
}

.how-it-works-wrapper-play-video-relative {
    position: relative;
    width: 100%;
}

.how-it-works-wrapper-play-video {
    position: absolute;
    right: 0;
    margin-top: 60px;
    padding-right: 20px;
    padding-top: 16px;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;
    cursor: pointer;
}

.how-it-works-wrapper-play-video-center-notmobile {
    width: 100%;
    text-align: center;
    font-family: Rota, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;
    cursor: pointer;
    margin-bottom: 30px;
}

.how-it-works-wrapper-play-video-center {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.vezi-video-ml {
    margin-left: 10px;
}

@media (max-width: 767px) {
    .main-screen-gradient2 .homepage-benefits h1 {
        padding: 40px 0;
        text-align: center;
        font-family: Rota, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 110%;
        color: #000000;
    }

    .homepage-benefits .row {
        margin: 0;
        padding: 0;
    }

    .homepage-benefits .benefit-item {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .homepage-benefits .benefit-item .benefit-img-wrapper {
        width: 100px;
        height: 100px;
    }

    .homepage-permanent-access {
        padding: 35px 53px;
    }

    .homepage-permanent-access p {
        font-size: 24px;
        line-height: 110%;
        width: 100%;
    }

    .homepage-permanent-access .orange-button {
        width: 100%;
    }

    .homepage-feedback h1 {
        font-size: 32px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .homepage-newsletter {
        padding: 40px 20px;
    }

    .homepage-newsletter img {
        height: 70px;
    }

    .homepage-newsletter h1 {
        font-size: 32px;
    }

    .homepage-newsletter h4 {
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        margin-bottom: 20px;
        padding-left: 0;
        padding-right: 0;
    }

    .homepage-newsletter form {
        padding-left: 0;
        padding-right: 0;
    }

    .demo-image-wrapper .demo-img-mobile {
        max-width: 100%;
    }

    @supports (not (-ms-ime-align:auto)) {
        .homepage-newsletter input.form-control {
            width: 100%;
            text-align: left;
        }

        .homepage-newsletter .mailchimp-form .mailchimp-form-email-input {
            font-family: Rota, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #001315;
            padding: 19px 20px;
            height: 56px !important;
            transition: all 0.2s ease-out;
            background: #FFFFFF;
            border: 1px solid #E5E7E8;
            border-radius: 15px;
            width: 100%;
            margin: 0 auto;
        }

        .homepage-newsletter .mailchimp-form .mailchimp-form-email-input {
            display: block;
        }

        .homepage-newsletter .mailchimp-form .mailchimp-form-email-input {
            border: 1px solid #E5E7E8;
        }

        .homepage-newsletter input.form-control + label {
            left: 1%;
        }

        .homepage-newsletter .orange-button {
            right: 1%;
        }
    }

    .homepage-doctor-section {
        padding-left: 20px;
        padding-right: 0;
    }

    .homepage-doctor-section .homepage-doctor-section-logo {
        padding-right: 20px;
    }

    .homepage-doctor-section .homepage-doctor-section-img {
        padding-right: 0;
    }

    .homepage-doctor-section h1 {
        font-size: 32px;
        margin-bottom: 26px;
        padding-right: 20px;
    }

    .homepage-doctor-section .green-button {
        width: calc(100% - 20px);
        margin: 0 0 40px;
    }

    .main-screen-gradient2 .homepage-specializations h1 {
        font-size: 32px;
        padding: 40px 0 30px;
    }

    .apps-button {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

    }

    .apps-button a {
        width: 50%;
        display: flex;
        justify-content: flex-start;
    }

    .how-it-works-wrapper :not(.specializations-carousel) .apps-button a {
        margin-bottom: 10px;
    }

    .apps-button a:first-child {
        margin-right: 10px;
        justify-content: flex-end;
    }

    .specializations-carousel .box .row {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .specializations-carousel .box .row .col-12:not(.justify-content-center) {
        flex-grow: 0;
        flex-shrink: 1;
    }

    .specializations-carousel .box .row .col-12.justify-content-center {
        flex-grow: 1;
        flex-shrink: 0;
        justify-content: flex-start !important;
        margin-bottom: 10px;
    }

    .main-screen-gradient2 h1 {
        padding: 40px 20px 30px;
        font-size: 32px;
        line-height: 35px;
    }

    .demo-img-mobile {
        margin-top: 20px;
        width: 100%;
    }

    .main-screen-gradient2 .homepage-specializations {
        padding-bottom: 100px;
    }

    .main-screen-gradient2 .specializations-carousel .next-slider {
        bottom: -80px;
        top: auto;
        right: 30%;
        height: 56px;
        width: 56px;
    }

    .main-screen-gradient2 .specializations-carousel .prev-slider {
        bottom: -80px;
        top: auto;
        left: 30%;
        height: 56px;
        width: 56px;
    }

    .main-screen-gradient3 .feedback-carousel {
        padding-bottom: 25px;
    }

    .main-screen-gradient3 .defiro-carousel {
        padding-bottom: 25px;
    }

    .how-it-works-wrapper .specializations-carousel {
        padding-bottom: 100px;
    }

    .main-screen-gradient3 .feedback-carousel .next-slider {
        bottom: -80px;
        top: auto;
        right: 30%;
        height: 56px;
        width: 56px;
    }

    .main-screen-gradient3 .defiro-carousel .next-slider {
        bottom: -80px;
        top: auto;
        right: 30%;
        height: 56px;
        width: 56px;
    }

    .main-screen-gradient3 .feedback-carousel .prev-slider {
        bottom: -80px;
        top: auto;
        left: 30%;
        height: 56px;
        width: 56px;
    }

    .main-screen-gradient3 .defiro-carousel .prev-slider {
        bottom: -80px;
        top: auto;
        left: 30%;
        height: 56px;
        width: 56px;
    }

    .how-it-works-wrapper .specializations-carousel .next-slider {
        bottom: -80px;
        top: auto;
        right: 30%;
        height: 56px;
        width: 56px;
    }

    .how-it-works-wrapper .specializations-carousel .prev-slider {
        bottom: -80px;
        top: auto;
        left: 30%;
        height: 56px;
        width: 56px;
    }

    .homepage-newsletter .mailchimp-form {
        width: 100%;
    }

}

@media (max-width: 1199px) {
    .accordion-wrapper-div {
        width: 100%;
    }

}

@media (max-width: 991px) {
    .homepage-benefits .benefit-item {
        padding-left: 50px;
        padding-right: 50px;
    }

    .homepage-benefits .benefit-item .title {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .main-screen-gradient2 .box-white .accordion-button {
        padding: 16px 15px;
    }
}

.platform-login-button-container {
    width: 100%;
}

.platform-login-button-container .column-login {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
}

.container-noMobile {
    display: flex;
    flex-wrap: wrap;
    padding-left: 12px;
    justify-content: space-between;
}

.container-noMobile .box {
    flex: 0 0 32.5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.container-noMobile .box .row {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start !important;
    margin-bottom: 10px;
}

.container-noMobile .box-img {
    margin-top: auto;
    margin-bottom: 0px;
}

.platform-login-button-container {
    width: 100%;
}

.platform-login-button-container .column-login {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
}

.navigation .column {
    width: 170px;
}

.defiro-carousel-product {
    text-align: center;
}

.defiro-carousel-product a {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
}

.defiro-carousel-product img {
    width: 100%;
}


section.main-hero-1 {
    /*width: 100vw;*/
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;

    .main-hero-1-main {
        background-image: url('../../shared/assets/images/Background_Imagex2.png'); /* Schimbă cu URL-ul imaginii */
        background-position: right bottom; /* Poziționează în colțul dreapta-jos */
        background-size: cover; /* Acoperă întreaga zonă vizibilă */
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .main-hero-1-section-like-menu {
            width: 100%;
            height: 12%;

            .main-hero-1-container-menu {
                width: 1200px;
                height: 100%;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .public-header-menu {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .public-header-menu-logo {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }

                    .public-header-menu-mid {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .menu-link {
                            padding: 10px 12px;
                            font-family: Rota, sans-serif;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }

                    .public-header-menu-right {
                        display: flex !important;
                        flex-direction: row;
                        justify-content: end;
                        position: relative;

                        .public-header-menu-right-container {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;

                            .menu-link {
                                padding: 10px 12px;
                                font-family: Rota, sans-serif;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 20px;

                                &.i-m-doctor {
                                    color: #052E29B2
                                }

                                &.create-account {
                                    background-color: #FFFFFF;
                                    border-radius: 12px;
                                }
                            }
                        }

                        .public-header-menu-right-language-container {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            .public-language-selector-wrapper {
                                padding: 6px 12px;
                                border: 1px solid #7E97B4;
                                border-radius: 12px;
                                font-family: Rota, sans-serif;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 20px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                position: absolute;
                                right: -75px;

                                span {
                                    padding: 0;
                                    width: 100%;
                                    font-family: Rota, sans-serif;
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 14px;
                                    line-height: 20px;

                                    & > img {
                                        margin: 0;
                                        margin-bottom: 2px;
                                        margin-left: 7px;
                                    }
                                }

                                .language-options {
                                    background: #FFFFFF;
                                    padding: 10px 12px;
                                    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15);
                                    border-radius: 12px;
                                    position: absolute;
                                    top: 100%;
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .main-hero-1-section {
            /*width: 1200px;*/
            flex-grow: 1;
            display: flex;
            align-items: center;

            .main-hero-1-container {
                width: 1200px;
                height: 100%;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .main-hero-1-container-content{
                    width: 42%;

                    .sessions{
                        margin-bottom: 16px;
                        font-family: "Noto Sans", sans-serif;
                        font-optical-sizing: auto;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 28px;
                    }

                    .content-h1{
                        font-family: Rota, sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 74px;
                        line-height: 76px;
                        letter-spacing: -1px;
                    }

                    .content-text{
                        font-family: Rota, sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 28px;
                    }

                    .content-btn{
                        margin-top: 32px;
                    }
                }
            }
        }
    }

    .main-hero-1-section-like-store {
        width: 100%;
        height: 13%;
        margin-top: 0px;
        background-color: #FFFFFF;

        .main-hero-1-section-like-store-container {
            width: 1200px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 100%;

            .main-hero-1-section-like-store-info {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .title {
                    font-family: "Rota";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                    color: #131920;
                }

                .content {
                    font-family: "Noto Sans", sans-serif;
                    font-optical-sizing: auto;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #384B61;
                }
            }

            .main-hero-1-container-store-btns {
                display: flex;
                flex-direction: row;
                align-items: center;

                a {
                    text-decoration: none;
                }

                .separator {
                    border-left: 1px solid #BFCBD9;
                    height: 58px;
                    margin-left: 32px;
                }

                .store {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    &.store-google {
                        padding-left: 32px;
                    }

                    .btn {
                        height: 64px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 8px 12px;
                        border: 2px solid #7E97B4;
                        border-radius: 12px;
                        margin-right: 16px;

                        .btn-text {
                            .btn-text-info {
                                font-family: "Rota";
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                color: #131920;
                                text-align: left;
                            }

                            .btn-text-store {
                                font-family: "Rota";
                                font-style: normal;
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 36px;
                                color: #131920;
                                margin-top: -10px;
                            }
                        }
                    }

                    .app-rating {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .app-rating-star {
                            margin-bottom: 5px;
                        }

                        .app-rating-rating {
                            font-family: "Rota";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 28px;
                            color: #384B61;

                            .rating-value {
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }

    }
}

section.ai {
    background-color: #E5EAF0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;

    .ai-container {
        width: 1200px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .ai-info {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .pre-header {
                display: flex;
                flex-direction: row;

                img {
                    margin-right: 8px;
                }

                .pre-header-info {
                    font-family: "Rota";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                    color: #131920;
                }
            }

            .ai-header {
                font-family: "Rota";
                font-style: normal;
                font-weight: 700;
                font-size: 42px;
                line-height: 56px;
                color: #131920;
                margin-top: 16px;
            }

            .ai-content {
                font-family: "Noto Sans", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #384B61;
            }

            .ai-start-conversation {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                .white-button-new {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    box-shadow: 1px 1px 2px rgba(22, 24, 55, 0.1),
                    -1px 1px 2px rgba(22, 24, 55, 0.1); /* Umbră subtilă */
                    align-self: flex-start;
                    width: 280px;
                    margin-top: 16px;

                    .white-button-new-text {
                        font-family: "Rota";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        color: #131920;
                    }
                }
            }

            .note {
                font-family: "Noto Sans", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 16px;
                color: #384B61;
                margin-top: 64px;
            }
        }

        .ai-dr-img {
            img {
                width: 600px;
                height: 560px;
            }
        }
    }
}

section.reputation {
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #E5EAF0;
    padding-bottom: 70px;

    .reputation-container {
        width: 1200px;
        position: relative;

        .reputation-container-title {
            font-family: "Rota";
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #131920;
            margin-top: 24px;
            margin-bottom: 24px;
        }

        .custom-nav {
            background: none;
            position: absolute;
            bottom: -50px;
            left: 10px;
            display: flex;
            gap: 10px;
            z-index: 10;
        }

        .custom-prev {
            background: none;
            border: none;
            padding: 8px 12px;
            cursor: pointer;

            .icon {
                width: 8px;
                height: 14px;
                background-color: #384B61; /* defines the background color of the image */
                mask: url(shared/assets/icons/leftArrow.svg) no-repeat center;
                -webkit-mask: url(shared/assets/icons/leftArrow.svg) no-repeat center;
            }

            &.swiper-button-disabled {
                opacity: 0.35;
                cursor: auto;
                pointer-events: none;
            }
        }

        .custom-next {
            background: none;
            border: none;
            padding: 8px 12px;
            cursor: pointer;

            .icon {
                width: 8px;
                height: 14px;
                background-color: #384B61; /* defines the background color of the image */
                mask: url(shared/assets/icons/rightArrow.svg) no-repeat center;
                -webkit-mask: url(shared/assets/icons/rightArrow.svg) no-repeat center;
            }

            &.swiper-button-disabled {
                opacity: 0.35;
                cursor: auto;
                pointer-events: none;
            }
        }

        .swiper {
            .swiper-slide {
                height: 208px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .item {
                    background: #FFFFFF;
                    border-radius: 12px;
                    display: flex;
                    flex-direction: column;
                    padding: 16px;

                    .content {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .info {
                            display: flex;
                            flex-direction: column;
                            width: 70%;

                            .hospital-name {
                                font-family: "Rota", sans-serif;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 32px;
                                color: #131920;
                                max-height: 64px;
                                overflow: hidden;
                            }

                            .hospital-town {
                                font-family: "Noto Sans", sans-serif;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: #131920;
                            }

                            .hospital-doctors-info {
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                .all-doctors {
                                    font-family: "Noto Sans", sans-serif;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 24px;
                                    color: #131920;
                                    margin-right: 10px;
                                }

                                .online-doctors {
                                    font-family: "Noto Sans", sans-serif;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 24px;
                                    color: #4B6481;
                                    border: 1px solid #BFCBD9;
                                    border-radius: 4px;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    padding: 2px 4px;
                                }
                            }
                        }

                        .img {
                            width: 30%;

                            img {
                                width: 100%;
                            }
                        }
                    }

                    .btn-container {
                        margin-top: 16px;
                        display: none;

                        .btn {
                            border: 1px solid #15B7A3;
                            width: 100%;
                        }
                    }

                    &.active {
                        .btn-container{
                            display: inline-block;
                        }
                    }

                    &:hover {
                        .btn-container{
                            display: inline-block;
                        }
                    }
                }

            }

            &:hover {
                .swiper-slide {
                    .item {
                        .btn-container {
                            display: none;
                        }

                        &:hover {
                            .btn-container{
                                display: inline-block;
                            }
                        }
                    }
                }

            }
        }
    }
}

section.main-informtion {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #FFFFFF;

    .main-informtion-container {
        width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            text-align: center;
            font-family: "Rota", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 42px;
            line-height: 56px;
            color: #131920;
        }

        .content {
            font-family: "Noto Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #384B61;
            width: 600px;
            text-align: center;
            margin-top: 8px;
        }

        .features {
            display: flex;
            flex-direction: row;
            gap: 32px;
            margin-top: 32px;

            .left {
                display: flex;
                flex-direction: column;
                gap: 32px;
            }

            .right {
                display: flex;
                flex-direction: column;
                gap: 32px;
            }
        }

        .create-account {
            margin-top: 32px;
        }
    }
}

section.how-it-works {
    background-color: #E5EAF0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 80px;

    .how-it-works-container {
        width: 1200px;
        display: flex;
        flex-direction: column;

        .title {
            font-family: "Rota", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #131920;
        }

        .how-it-works-content {
            display: flex;
            flex-direction: row;
            gap: 16px;

            .how-it-works-card {
                background-color: #FFFFFF;
                padding: 16px;
                display: flex;
                flex-direction: column;
                width: 288px;
                min-height: 440px;
                justify-content: space-between;
                border-radius: 12px;

                .hiw-counter {
                    width: 48px;
                    height: 48px;
                    font-family: "Rota", sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 24px;
                    color: #FFFFFF;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    border-radius: 12px;
                    padding-top: 3px;
                    position: absolute;
                    margin-left: -8px;
                    margin-top: -8px;

                    &.counter-1 {
                        background-color: #FF6F00;
                    }

                    &.counter-2 {
                        background-color: #15B7A3;
                        color: #052E29;
                    }

                    &.counter-3 {
                        background-color: #2A8CFF;
                    }
                }

                .card-content {

                    .title {
                        font-family: "Rota", sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 32px;
                        color: #131920;
                    }

                    .description {
                        font-family: "Noto Sans", sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #384B61;
                    }
                }

                .card-button {
                    background-color: #FFFFFF;
                    padding: 10px 12px;
                    font-family: "Rota", sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    color: #131920;

                    &.store-buttons {
                        display: flex;
                        flex-direction: row;
                        gap:16px;
                    }
                }
            }
        }
    }
}

section.review {
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    padding-bottom: 70px;


    .review-container {
        width: 1200px;
        position: relative;

        .review-container-title {
            font-family: "Rota";
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #131920;
            margin-top: 24px;
            margin-bottom: 24px;

        }

        .swiper {
            .swiper-slide {
                height: 245px;
                padding-bottom: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }

        .review-carousel-item {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #FFFFFF;
            border-radius: 12px;
            padding: 16px;
            border: 1px solid rgba(22, 24, 25, 0.1); /* #16183733*/
            box-shadow: 1px 1px 2px rgba(22, 24, 25, 0.1),
            -1px 1px 2px rgba(22, 24, 25, 0.1); /* Umbră subtilă */

            .review-data {
                display: flex;
                flex-direction: column;

                .header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .patient-info {
                        display: flex;
                        flex-direction: column;

                        .patient-name {
                            font-family: "Rota", sans-serif;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 24px;
                            color: #131920;
                        }

                        .patient-location {
                            font-family: "Noto Sans", sans-serif;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #131920;

                        }
                    }
                }

                .review-message {
                    font-family: "Noto Sans", sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #131920;
                }
            }

            .review-doctor {
                background-color: #F2F5F7;
                border-radius: 12px;
                padding: 8px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .review-doctor-info {

                    .dr-name {
                        font-family: "Rota", sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: #131920;
                    }

                    .dr-specialization {
                        font-family: "Noto Sans", sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #384B61;
                    }
                }

                .review-doctor-img {

                    .avatar {
                        img {
                            border-radius: 50%;
                        }

                        .image-placeholder {
                            border-radius: 50%;
                        }
                    }
                }
            }
        }

        .custom-nav {
            background: none;
            position: absolute;
            bottom: -50px;
            left: 10px;
            display: flex;
            gap: 10px;
            z-index: 10;
        }

        .custom-reviews-prev {
            background: none;
            border: none;
            padding: 8px 12px;
            cursor: pointer;

            .icon {
                width: 8px;
                height: 14px;
                background-color: #384B61; /* defines the background color of the image */
                mask: url(shared/assets/icons/leftArrow.svg) no-repeat center;
                -webkit-mask: url(shared/assets/icons/leftArrow.svg) no-repeat center;
            }

            &.swiper-button-disabled {
                opacity: 0.35;
                cursor: auto;
                pointer-events: none;
            }
        }

        .custom-reviews-next {
            background: none;
            border: none;
            padding: 8px 12px;
            cursor: pointer;

            .icon {
                width: 8px;
                height: 14px;
                background-color: #384B61; /* defines the background color of the image */
                mask: url(shared/assets/icons/rightArrow.svg) no-repeat center;
                -webkit-mask: url(shared/assets/icons/rightArrow.svg) no-repeat center;
            }

            &.swiper-button-disabled {
                opacity: 0.35;
                cursor: auto;
                pointer-events: none;
            }
        }

    }
}

section.specializations {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-bottom: 0 !important;

    .specializations-container {
        width: 1200px;
        display: flex;
        flex-direction: column;

        .title {
            font-family: "Rota", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #131920;
            margin-bottom: 24px;
        }

        .specializations-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .specializations-list {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .specialization-list-item {
                    background-color: #F2F5F7;
                    padding: 8px 12px;
                    margin-right: 16px;
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;

                    .name {
                        font-family: "Rota", sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        color: #131920;
                    }
                }
            }

            .specialization-see-more {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                /*align-items: end;*/

                .specialization-see-more-btn {
                    background-color: #263240;
                    padding: 10px 16px;
                    font-family: "Rota", sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    color: #FFFFFF;
                    border-radius: 12px;
                    white-space: nowrap;
                    margin-bottom: 16px;
                }
            }
        }
    }
}

section.ways-to-make-a-call {
    background-color: #E5EAF0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 80px;

    .ways-to-make-a-call-container {
        width: 1200px;
        display: flex;
        flex-direction: column;

        .title {
            font-family: "Rota", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #131920;
            margin-bottom: 24px;
        }

        .ways-to-make-a-call-container-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 40px;
            border-radius: 12px;
            height: 432px;

            .accordion-container {
                border-radius: 12px;
                flex: 1;
                display: flex;
                align-items: center;

                .accordion {
                    border-radius: 12px;

                    .accordion-item {
                        background-color: #FFFFFF;

                        .accordion-header {
                            background-color: #FFFFFF;

                            .accordion-button {
                                background-color: #FFFFFF;
                                box-shadow: none;
                                display: flex;
                                align-items: center;

                                .circle {
                                    background-color: #15B7A3;
                                    width: 48px;
                                    height: 48px;
                                    padding-top: 3px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-family: "Rota", sans-serif;
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 24px;
                                    line-height: 32px;
                                    color: #052E29;
                                    border-radius: 12px;
                                    margin-right: 20px;
                                }

                                .description {
                                    font-family: "Rota", sans-serif;
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 24px;
                                    line-height: 32px;
                                    color: #131920;
                                    padding-top: 3px;
                                }

                                &.collapsed {
                                    .circle {
                                        background-color: #BFCBD9;
                                        color: #6E8AAAB3;
                                    }
                                }
                            }
                        }

                        &#acc0 {
                            .accordion-header {
                                border-top-left-radius: 12px;
                                border-top-right-radius: 12px;
                            }
                        }
                        &#acc2 {
                            .accordion-header {
                                border-bottom-left-radius: 12px;
                                border-bottom-right-radius: 12px;
                            }
                        }

                        .accordion-body {
                            font-family: "Noto Sans", sans-serif;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 28px;
                            color: #384B61;

                        }
                    }
                }
            }

            .accordion-image-container {
                flex: 1;
                display: flex;
                justify-content: center;

                img {
                    /*width: 320px;*/
                    height: 100%
                }
            }
        }
    }
}

section.are-you-doctor {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 80px;

    .are-you-doctor-container {
        width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            text-align: center;
            font-family: "Rota", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #131920;
        }

        .content {
            font-family: "Noto Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #384B61;
            width: 600px;
            text-align: center;
            margin-top: 8px;

            .content-btn {
                .green-button-new {
                    margin-top: 16px;
                    color: #052E29;
                }
            }
        }

        .features {

            img {
                width: 100%;
            }
        }
    }
}

section.newsletter {
    background-color: #263240;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #384B61;

    .newsletter-container {
        width: 1200px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .newsletter-info {
            display: flex;
            flex-direction: row;

            .newsletter-info-icon {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: 24px;
            }

            .newsletter-info-content {
                display: flex;
                flex-direction: column;

                .title {
                    font-family: "Rota", sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    color: #FFFFFF;
                }

                .content {
                    font-family: "Noto Sans", sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #FFFFFF;
                }
            }
        }

        .newsletter-form {
            margin-left: 24px;

            .mailchimp-form {
                width: 400px;
                position: relative;
            }

            .mailchimp-form {
                /*display: flex;*/
                /*flex-direction: column-reverse;*/

                .mailchimp-form-email-input {
                    background-color: #FFFFFF;
                    &.form-control {
                        padding: 12px 18px 10px 18px;
                    }
                }

            }

            .mailchimp-form .success-response {
                font-family: Rota, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 110%;
                color: #00CBB2;
                padding-top: 10px;
                position: absolute;
                margin-top: 54px;
            }

            .mailchimp-form .error-response {
                font-family: Rota, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 110%;
                color: #EB3300;
                padding-top: 10px;
                position: absolute;
                margin-top: 54px;
            }

            .mailchimp-form .mailchimp-form-button {
                background-color: #FF6A39;
                border-radius: 12px;
                font-family: Rota, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                text-align: center;
                color: #FFFFFF;
                border: 2px solid #FF6A39;
                text-decoration: none;
                position: absolute;
                top: 3px;
                width: auto;
                right: 4px;
                padding: 10px;
                min-height: auto;
            }

            .mailchimp-form .mailchimp-form-status {
                font-family: Rota, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: #00CFB4;
            }
        }
    }
}

.main-screen-page footer {
    margin-top: 0px !important;
}


.is-mobile {
    height: auto;
    display: flex;
    flex-direction: column;

    section.main-hero-1 {
        justify-content: flex-start;
        height: auto;

        .main-hero-1-main {
            background-image: none;
            height: auto;

            .main-hero-1-section-like-menu {
                height: 56px;
                width: 100%;

                .main-hero-1-container-menu {
                    width: 100%;
                }
            }

            .main-hero-1-section {
                display: flex;
                flex-direction: column;

                .main-hero-1-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-left: 16px;
                    padding-right: 16px;
                    width: 100%;
                    justify-content: flex-start;
                    height: auto;

                    .main-hero-1-container-content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;

                        .sessions {
                            font-size: 20px;
                            line-height: 28px;
                        }

                        .content-h1 {
                            font-size: 42px;
                            line-height: 56px;
                            text-align: center;
                        }

                        .content-text {
                            font-size: 20px;
                            line-height: 28px;
                            text-align: center;

                        }
                    }
                }

                .main-img {
                    /*position: relative;*/
                    padding-top: 16px;

                    .content-btn {
                        position: absolute;
                        left: 50%; /* Mută punctul de referință la mijloc */
                        transform: translateX(-50%); /* Îl repoziționează înapoi cu jumătate din lățime */
                    }

                    img {
                        margin-top: 16px;
                    }
                }
            }
        }

        .main-hero-1-section-like-store {
            height: auto;

            .main-hero-1-section-like-store-container {
                width: 100%;
                flex-direction: column;
                align-items: center;
                padding: 16px 32px;

                .main-hero-1-section-like-store-info {
                    align-items: center;

                    .content {
                        text-align: center;
                    }
                }

                .main-hero-1-container-store-btns {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 16px;

                    .store {
                        flex-direction: column;
                        align-items: center;

                        .btn {
                            padding: 6px 12px;
                            margin-right: auto;

                            .btn-text {

                                .btn-text-info {
                                    font-size: 11px;
                                    line-height: 15px;
                                }

                                .btn-text-store {
                                    font-size: 18px;
                                    line-height: 27px;
                                    margin-top: 0;
                                }
                            }
                        }

                        .app-rating {
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }

    section.ai {

        .ai-container {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            flex-direction: column;
            align-items: center;

            .ai-info {

                .pre-header {
                    justify-content: center;
                }

                .ai-header {
                    text-align: center;
                }

                .ai-content {
                    text-align: center;
                }

                .ai-start-conversation {

                    .white-button-new {
                        flex-grow: 1;
                    }
                }
            }

            .ai-dr-img {
                img {
                    width: 100%;
                    height: auto;
                }
            }

            .note {
                font-family: "Noto Sans", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 16px;
                color: #384B61;
                /*margin-top: 64px;*/
            }
        }
    }

    section.reputation {

        .reputation-container {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            flex-direction: column;
            align-items: center;

            .reputation-container-title {
                text-align: center;
            }

            .swiper-slide {

                &.swiper-slide-active {

                    .item {

                        .btn-container {
                            display: inline-block;
                        }
                    }
                }
            }

            .custom-nav {
                left: 50%; /* Mută punctul de referință la mijloc */
                transform: translateX(-50%); /* Îl repoziționează înapoi cu jumătate din lățime */

            }

        }
    }

    section.main-informtion {

        .main-informtion-container {
            width: 100%;
            padding: 0 16px;

            .title {
                padding: 0 20px;
            }

            .content {
                width: 100%;
            }
        }
    }

    section.how-it-works {
        padding-top: 40px;

        .how-it-works-container {
            width: 100%;
            padding: 0 16px;
            flex-direction: column;
            align-items: center;

            .how-it-works-content {
                width: 100%;
                flex-direction: column;
                align-items: center;
                padding-top: 24px;

                .how-it-works-card {
                    width: 100%;
                    min-height: auto;

                    .card-content {
                        margin-bottom: 12px;
                    }

                    .card-button {
                        padding: 0;
                        justify-content: flex-start;
                    }
                }
            }
        }
    }

    section.review {

        .review-container {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            flex-direction: column;
            align-items: center;

            .review-container-title {
                text-align: center;
            }

            .swiper-slide {

                &.swiper-slide-active {

                    .item {

                        .btn-container {
                            display: inline-block;
                        }
                    }
                }
            }

            .custom-nav {
                left: 50%; /* Mută punctul de referință la mijloc */
                transform: translateX(-50%); /* Îl repoziționează înapoi cu jumătate din lățime */

            }

        }
    }

    section.specializations {
        padding-top: 40px;

        .specializations-container {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            flex-direction: column;
            align-items: center;

            .specializations-content {
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                .specializations-list {
                    justify-content: center;

                    .specialization-list-item {
                        margin-right: 8px;

                        .name {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }

    section.ways-to-make-a-call {
        width: 100%;

        .ways-to-make-a-call-container {
            width: 100%;
            flex-direction: column;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;

            .title {
                text-align: center;
            }

            .ways-to-make-a-call-container-content {
                flex-direction: column;
                align-items: center;
                height: auto;

                .accordion-container {
                    width: 100%;

                    .accordion {
                        width: 100%;

                        .accordion-item {

                        }
                    }
                }
            }
        }
    }

    section.are-you-doctor {
        width: 100%;
        padding-top: 40px;

        .are-you-doctor-container {
            width: 100%;
            flex-direction: column;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;

            .content {
                width: 100%;
            }
        }
    }

    section.newsletter {
        width: 100%;

        .newsletter-container {
            width: 100%;
            flex-direction: column;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;

            .newsletter-info {
                flex-direction: column;
                margin-bottom: 24px;

                .newsletter-info-icon {
                    margin-bottom: 14px;
                }

                .newsletter-info-content {

                    .title {
                        margin-bottom: 6px;
                    }
                }
            }

            .newsletter-form {
                margin-left: auto;
                width: 100%;

                .mailchimp-form {
                    width: 100%;
                    position: relative;
                }

            }
        }
    }
}

@media (width <= 380px) {
    .is-mobile {
        section.reputation {
            .reputation-container {
                .swiper {
                    .swiper-slide {
                        .item {
                            .content {
                                .info {
                                    .hospital-name {
                                        font-size: 18px;
                                        line-height: 24px;
                                    }

                                    .hospital-town {
                                        font-size: 12px;
                                        line-height: 16px;
                                    }

                                    .hospital-doctors-info {

                                        .all-doctors {
                                            font-size: 12px;
                                            line-height: 16px;
                                        }

                                        .online-doctors {
                                            font-size: 12px;
                                            line-height: 16px;
                                        }
                                    }
                                }
                            }

                            .btn-container {
                                .btn {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }

        section.newsletter {

            .newsletter-container {

                .newsletter-form {
                    width: 100%;
                }
            }
        }
    }
}