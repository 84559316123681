.doctors-page {
    section.menu-section {
        padding: 8px 20px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);


        .public-header-menu {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .public-header-menu-logo {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .public-header-menu-mid {
                display: flex;
                flex-direction: row;

                .menu-link {
                    padding: 10px 12px;
                    font-family: Rota, sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            .public-header-menu-right {
                display: flex !important;
                flex-direction: row;
                justify-content: end;
                position: relative;

                .public-header-menu-right-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    .menu-link {
                        padding: 10px 12px;
                        font-family: Rota, sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;

                        &.i-m-doctor {
                            color: #052E29B2
                        }

                        &.create-account {
                            background-color: #263240;
                            color: #FFFFFF;
                            border-radius: 12px;
                        }
                    }
                }

                .public-header-menu-right-language-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 8px;

                    .public-language-selector-wrapper {
                        padding: 6px 12px;
                        border: 1px solid #7E97B4;
                        border-radius: 12px;
                        font-family: Rota, sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        /*position: absolute;*/
                        /*right: -75px;*/

                        span {
                            padding: 0;
                            width: 100%;
                            font-family: Rota, sans-serif;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 20px;

                            & > img {
                                margin: 0;
                                margin-bottom: 2px;
                                margin-left: 7px;
                            }
                        }

                        .language-options {
                            background: #FFFFFF;
                            padding: 10px 12px;
                            box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15);
                            border-radius: 12px;
                            position: absolute;
                            top: 100%;
                            right: 0;
                        }
                    }
                }
            }
        }
    }

    section.doctors-page-title {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .doctors-page-title-content {
            width: 1200px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 8px 0px;

            .title-and-breadcrumbs {
                display: flex;
                flex-direction: column;

                .breadcrumb {
                    margin-top: auto;

                    &.breadcrumb-main-page {
                        padding-left: 0px;

                        .breadcrumb-item {
                            font-family: "Noto Sans", sans-serif;
                            font-optical-sizing: auto;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 20px;
                            color: #384B61;
                        }
                    }
                }

                .title {
                    font-family: Rota, sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;

                }
            }

            .how-does-it-works {
                .white-button-new {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px 20px;

                    img {
                        width: 16px;
                        margin-top: -4px;
                        margin-right: 12px;
                    }

                    .text {
                        font-family: Rota, sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 21px;
                        line-height: 32px;

                    }
                }
            }
        }
    }

    section.filters {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #F2F5F7;

        .filters-container {
            width: 1200px;
            display: flex;
            flex-direction: row;
            padding-top: 8px;
            padding-bottom: 8px;

            .filters-search {
                width: calc((100% - 48px) / 3); /* 3 pe rând, ținând cont de gap */

                input {
                    width: 100%;
                    height: 40px;
                    border: 1px solid #DBE0E6;
                    border-radius: 12px;
                    padding-left: 16px;
                    background-color: #FFFFFF;
                    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15);
                    outline: none;
                    font-family: "Noto Sans", sans-serif;
                    font-optical-sizing: auto;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #4B6481;
                }

                img {
                    position: absolute;
                    margin-left: -28px;
                    margin-top: 10px;
                    width: 18px;
                    height: 18px;
                }
            }

            .filters-sorting {
                display: flex;
                flex-direction: row;
                /*flex-grow: 1;*/
                margin-left: 8px;
                width: 300px;

                .sorting-elements {
                    width: 100%;
                    height: 40px;
                    border: 1px solid #DBE0E6;
                    border-radius: 12px;
                    background-color: #FFFFFF;
                    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 16px;
                    padding-right: 16px;
                    font-family: "Noto Sans", sans-serif;
                    font-optical-sizing: auto;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #4B6481;


                    img {
                        margin-left: 16px;
                    }
                }

                .sorting-modal {
                    background-color: #FFFFFF;
                    position: absolute;
                    width: 300px;
                    /*display: none;*/
                }
            }

            .filters-online-offline {
                display: flex;
                flex-direction: row;
                margin-left: 8px;

                .online-offline-elements {
                    width: 100%;
                    height: 40px;
                    border: 1px solid #DBE0E6;
                    border-radius: 12px;
                    background-color: #FFFFFF;
                    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 16px;
                    padding-right: 16px;
                    font-family: "Noto Sans", sans-serif;
                    font-optical-sizing: auto;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #4B6481;


                    img {
                        margin-left: 16px;
                    }
                }
            }

            .filters-specializations {
                display: flex;
                flex-direction: row;
                margin-left: 8px;
                flex-grow: 1;

                .specializations-modal {
                    background-color: #FFFFFF;
                    position: absolute;
                    width: 300px;
                    /*margin-top: 20px;*/
                    margin-left: -78px;
                    /*display: none;*/
                }

                .specializations-elements {
                    width: 100%;
                    height: 40px;
                    border: 1px solid #DBE0E6;
                    border-radius: 12px;
                    background-color: #FFFFFF;
                    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 16px;
                    padding-right: 16px;
                    font-family: "Noto Sans", sans-serif;
                    font-optical-sizing: auto;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #4B6481;


                    img {
                        margin-left: 16px;
                    }
                }
            }

            .filters-organizations {
                display: flex;
                flex-direction: row;
                margin-left: 8px;

                .organizations-modal {
                    background-color: #FFFFFF;
                    position: absolute;
                    width: 300px;
                    /*margin-top: 20px;*/
                    margin-left: -78px;
                    /*display: none;*/
                }

                .organizations-elements {
                    width: 100%;
                    height: 40px;
                    border: 1px solid #DBE0E6;
                    border-radius: 12px;
                    background-color: #FFFFFF;
                    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 16px;
                    padding-right: 16px;
                    font-family: "Noto Sans", sans-serif;
                    font-optical-sizing: auto;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #4B6481;


                    img {
                        margin-left: 16px;
                    }
                }
            }

            .filters-cities {
                display: flex;
                flex-direction: row;
                margin-left: 8px;

                .area-modal {
                    background-color: #FFFFFF;
                    position: absolute;
                    width: 300px;
                    /*margin-top: 20px;*/
                    margin-left: -78px;
                    /*display: none;*/
                }
                .cities-elements {
                    width: 100%;
                    height: 40px;
                    border: 1px solid #DBE0E6;
                    border-radius: 12px;
                    background-color: #FFFFFF;
                    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 16px;
                    padding-right: 16px;
                    font-family: "Noto Sans", sans-serif;
                    font-optical-sizing: auto;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #4B6481;


                    img {
                        margin-left: 16px;
                    }
                }
            }

            .filters-others {
                display: flex;
                flex-direction: row;
                margin-left: 8px;

                .others-elements {
                    width: 100%;
                    height: 40px;
                    border: 1px solid #DBE0E6;
                    border-radius: 12px;
                    background-color: #FFFFFF;
                    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 16px;
                    padding-right: 16px;
                    font-family: "Noto Sans", sans-serif;
                    font-optical-sizing: auto;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #4B6481;

                    img {

                    }
                }
            }

            .filters-modal {
                border: 1px solid #DBE0E6;
                border-radius: 12px;
                background-color: #FFFFFF;
                box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15);

                .filters-modal-title-components {
                    display: flex;
                    flex-direction: column;
                    border-bottom: 1px solid #DBE0E6;

                    .filters-modal-title {
                        padding: 12px 16px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        font-family: Rota, sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        color: #1E2834;
                    }

                    .filters-reset {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        height: 40px;
                        padding: 10px 16px;
                        cursor: pointer;

                        img {
                            width: 15px;
                            height: 15px;
                            margin-right: 8px;
                        }

                    }
                }

                .filters-modal-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    height: 40px;
                    padding: 10px 16px;
                    margin-bottom: 8px;

                    &.custom-checkbox.align-center-checkbox .custom-control-label::before,
                    &.custom-radio.align-center-radio .custom-control-label::before {
                        top: 0;
                        bottom: 0;
                        right: 16px;
                        left: auto;
                        margin: auto;
                    }

                    .custom-control-label {
                        font-family: Rota, sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #1E2834;
                        width: 100%;
                    }
                }
            }
        }
    }

    section.doctors-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #F2F5F7;

        .doctors-list-container {
            width: 1200px;
            display: flex;
            flex-direction: row;

            & > div {
                width: 100%;

                .infinite-scroll-component {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 24px;
                    width: 100%;

                    .card-container {
                        width: calc((100% - 48px) / 3); /* 3 pe rând, ținând cont de gap */
                        display: flex;
                        flex-direction: column;
                        justify-content: stretch;

                        .box {
                            border: 1px solid #DBE0E6;
                            border-top-left-radius: 12px;
                            border-top-right-radius: 12px;
                            border-bottom-left-radius: 12px;
                            border-bottom-right-radius: 12px;
                            background-color: #F6F7F9;
                            height: 100%;
                            display: flex;
                            flex-direction: column;

                            .doctor-box {
                                background-color: #FFFFFF;
                                padding: 16px;
                                border-radius: 12px;
                                width: calc(100% + 2px);
                                margin-left: -1px;
                                margin-top: -1px;
                                display: flex;
                                flex-direction: row;

                                .doctor-box-info {
                                    width: 80%;
                                    display: flex;
                                    flex-direction: column;

                                    .full-name {
                                        font-family: Rota, sans-serif;
                                        font-style: normal;
                                        font-weight: 700;
                                        font-size: 20px;
                                        line-height: 24px;
                                        color: #1E2834;
                                    }

                                    .specialization-name {
                                        font-family: Rota, sans-serif;
                                        font-style: normal;
                                        font-weight: 700;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: #1E2834;
                                    }

                                    .rating {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        margin-top: 8px;

                                        img {
                                            width: 18px;
                                            margin-right: 11px;
                                        }

                                        .rating-value {
                                            font-family: Rota, sans-serif;
                                            font-style: normal;
                                            font-weight: 700;
                                            font-size: 16px;
                                            line-height: 24px;
                                            color: #000000;
                                            margin-right: 8px;
                                        }

                                        .rating-separator {
                                            height: 100%;
                                            border-left: 1px solid #DBE0E6;
                                            margin-right: 8px;
                                        }

                                        .rating-count {
                                            font-family: "Noto Sans", sans-serif;
                                            font-optical-sizing: auto;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 12px;
                                            line-height: 16px;
                                            color: #70849B;

                                        }
                                    }

                                    .map-info {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        margin-top: 4px;
                                        margin-bottom: 8px;

                                        img {
                                            width: 11px;
                                            color: #70849B;
                                            margin-right: 16px;
                                            margin-left: 4px;
                                        }

                                        .map-value {
                                            font-family: Rota, sans-serif;
                                            font-style: normal;
                                            font-weight: 700;
                                            font-size: 16px;
                                            line-height: 24px;
                                            color: #1E2834;
                                        }
                                    }

                                    .organization-info {
                                        .organization-value{
                                            font-family: Rota, sans-serif;
                                            font-style: normal;
                                            font-weight: 700;
                                            font-size: 14px;
                                            line-height: 20px;
                                            color: #1E2834;
                                        }
                                    }
                                }

                                .doctor-box-img {
                                    width: 20%;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;

                                    .doctor {
                                        height: 60%;
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-end;

                                        img {
                                            max-width: 72px;
                                            max-height: 72px;
                                            /*max-width: 100%;*/
                                            /*max-height: 100%;*/
                                            height: auto;
                                            width: auto;
                                            object-fit: contain;
                                        }
                                    }

                                    .organization {
                                        height: 40%;
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-end;

                                        img {
                                            max-width: 48px;
                                            max-height: 48px;
                                            /*max-width: 100%;*/
                                            /*max-height: 100%;*/
                                            height: auto;
                                            width: auto;
                                            object-fit: contain;
                                        }
                                    }
                                }

                            }

                            .appointment-box {
                                display: flex;
                                flex: 1;
                                flex-direction: column;
                                padding: 16px;
                                justify-content: space-between;

                                .services {
                                    display: flex;
                                    flex-direction: column;

                                    .service {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                        margin-bottom: 12px;

                                        &.empty {
                                            height: 20px
                                        }

                                        .service-name {
                                            font-family: Rota, sans-serif;
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 16px;
                                            line-height: 20px;
                                            color: #1E2834;

                                            &::first-letter {
                                                text-transform: capitalize;
                                            }
                                        }

                                        .service-price-time {
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: space-between;

                                            .service-price {
                                                font-family: Rota, sans-serif;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 16px;
                                                line-height: 20px;
                                                color: #FF6A39;
                                            }

                                            .price-time-separator {
                                                height: 100%;
                                                border-left: 1px solid #DBE0E6;
                                                margin-right: 8px;
                                                margin-left: 8px;
                                            }

                                            .service-time {
                                                font-family: Rota, sans-serif;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 16px;
                                                line-height: 20px;
                                                color: #70849B;
                                            }
                                        }
                                    }
                                }

                                .appointment-buttons {
                                    display: flex;
                                    flex-direction: column;

                                    .green-button-new {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: center;
                                        color: #01322B;
                                        align-items: center;

                                        .camera {
                                            color: #01322B;
                                            margin-right: 8px;
                                        }

                                        .call-now-btn-text {
                                            font-family: Rota, sans-serif;
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 16px;
                                            line-height: 20px;
                                            color: #01322B;
                                            padding-top: 1px;
                                        }
                                    }

                                    .yellow-button-new {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: center;
                                        color: #01322B;
                                        align-items: center;

                                        .busy {
                                            color: #01322B;
                                            margin-right: 8px;
                                        }

                                        .call-now-btn-text {
                                            font-family: Rota, sans-serif;
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 16px;
                                            line-height: 20px;
                                            color: #01322B;
                                            padding-top: 1px;
                                        }
                                    }

                                    .appointment-separator {
                                        display: flex;
                                        align-items: center;
                                        text-align: center;
                                        font-family: Rota, sans-serif;
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 20px;
                                        color: #70849B;
                                        margin-top: 12px;
                                        margin-bottom: 12px;

                                        &::before,
                                        &::after {
                                            content: "";
                                            flex: 1;
                                            border-bottom: 1px solid #DBE0E6;
                                            margin: 0 10px;
                                        }
                                    }

                                    .white-button-new {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: center;
                                        color: #0B2CA5;
                                        align-items: center;

                                        img {
                                            &.add-appointment {
                                                color: #0B2CA5;
                                                width: 15px;
                                                height: 15px;
                                                margin-right: 8px;
                                            }
                                        }

                                        .add-appointment-btn-text {
                                            font-family: Rota, sans-serif;
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 16px;
                                            line-height: 20px;
                                            color: #0B2CA5;
                                            padding-top: 3px;

                                            &::first-letter {
                                                text-transform: capitalize;
                                            }
                                        }
                                    }

                                    .schedule-availability {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: center;
                                        font-family: "Noto Sans", sans-serif;
                                        font-optical-sizing: auto;
                                        font-style: normal;
                                        font-weight: 300;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #384B61;
                                        margin-top: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}